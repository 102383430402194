// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  images: $(".images-container-inner")
};

class Slides {
  constructor(slider, slick) {
    this.slider = slider;
    this.slick = slick;
    this.init();
  }

  init() {
    this.addEventListeners();
    this.slider.on("afterChange", (slick, currentSlide) => {
      this.changeCurrentSlideNumber(slick, currentSlide);
    });
  }

  addEventListeners() {
    const currentSlider = this.slick.$slider[0];
    const element = currentSlider.closest(".single-project");
    const prev = element.querySelector(".prev-slide");
    const next = element.querySelector(".next-slide");

    prev.addEventListener("click", e => {
      e.preventDefault();
      this.slick.slickPrev();
    });

    next.addEventListener("click", e => {
      e.preventDefault();
      this.slick.slickNext();
    });
  }

  changeCurrentSlideNumber(slick, currentSlide) {
    const currentSlider = slick.currentTarget;
    const element = currentSlider.closest(".single-project");
    let newContent = 0;
    currentSlide.currentSlide < 9
      ? (newContent = "0" + (currentSlide.currentSlide + 1))
      : (newContent = currentSlide.currentSlide + 1);
    element.querySelector(".current-slide").innerHTML = newContent;
  }
}

sliders.images.on("init", (e, slick) => {
  new Slides(sliders.images, slick);
});

sliders.images.slick({
  dots: false,
  arrows: false,
  autoplay: true
});
